import moment from "moment";
import { BILLING_ENTITY_TYPE_INDIVIDUAL } from "@/constants/billingInformations";

export default {
  type: "sales-orders",
  expiration_time: moment().add(30, "days").format("YYYY-MM-DD"),
  code: null,
  excerpt: "",
  email: null,
  discounts: [],
  deliveryItemsState: {
    orderedItems: {},
    deliveredItems: {},
    remainingItems: {},
    orderDelivered: false,
  },
  invoicingItemsState: {
    orderedItems: {},
    deliveredItems: {},
    remainingItems: {},
    orderInvoiced: false,
  },
  relationshipNames: [
    "recipient",
    "organization",
    "allowedLocations",
    "salesInvoice",
    "sourceWarehouse",
  ],
  recipient: {
    type: "customers",
    id: null,
  },
  organization: {
    type: "organizations",
    id: null,
  },
  salesInvoice: {
    type: "sales-invoices",
    id: null,
  },
  sourceWarehouse: {
    type: "warehouses",
    id: null,
  },
  items: [],
  allowedLocations: [],

  billing_entity_type: BILLING_ENTITY_TYPE_INDIVIDUAL,
  billing_company_name: "N/A",
  billing_firstname: "N/A",
  billing_lastname: "N/A",
  billing_country: "Canada",
  billing_state: "Quebec",
  billing_city: "N/A",
  billing_zipcode: "N/A",
  billing_address: "N/A",
  billing_email: "N/A",
};
